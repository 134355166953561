<template>
  <div>
    <!-- SELECT SUBCATEGORÍAS  -->

    <div class="panel-header" style="margin-top: -45px; padding-bottom: 23px !important">
      <div class="cd-filter-block mb-0">
        <div class="cd-filter-content">
          <div class="col-md-12" style="padding: 0">
            <div class="cd-select">
              <div class="pt-section-title-1 title-custom-v" style="height: 54px">
                <h5 class="pt-section-title mb-0">Categ<span class="imp-word">oría</span></h5>
              </div>

              <!-- container buttons  -->
              <div class="col-md-12 d-flex align-items-center" style="padding: 0">
                <!-- select box -->
                <div class="col-md-10" style="padding: 0;">
                  <select
                    v-if="subCategories.length != 0"
                    v-model="selectedCategory"
                    name="subcategory"
                    class="select-filter select-step2 shadow-card"
                  >
                    <option
                      v-for="(item, idx) in subCategories"
                      :key="idx"
                      :value="item.categoria"
                      :selected="idx == '0'"
                      class="option-color"
                    >
                      {{ item.categoria.nombre }} 
                    </option>
                  </select>
                  
                  <select v-else name="subcategory" class="select-filter select-step2 shadow-card">
                    <option>Cargando...</option>
                  </select>
                </div>

                <!-- btn sort -->
                 <div class="col-md-2" style="max-width: 100px;">
                  <span @click="sortServiceLoaded()" class="btn btn-default-grad-purple-fill white-font btn-add-config">
                    <i v-if="!state_sort" class="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    <i v-else class="fa fa-sort-amount-asc" aria-hidden="true"></i>
                    <!-- <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> -->
                  </span>


                 </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- HEADER NAVEGACIÓN -->
    <header-nav-component
      v-if="category"
      :title="category.nombre"
      :back_="true"
      :loading="loading"
      @back="back"
      style="margin-top: 45px; margin-bottom: 30px"
    />

    <!-- SERVICIOS -->
    <transition name="slide-fade">
      <div
        v-show="loading"
        style="display: flex; flex-flow: row wrap; margin-top: 5px"
        class="pricing special sec-uping card-services-container"
      >
        <div v-for="(item, index) in services" :key="item.id" class="col-md-6 card-service">
          <card-service
            :item="item"
            :category="category"
            :subcategory="selectedCategory"
            :type="1"
            :index="index"
            @next="next_step"
          />
        </div>
      </div>
    </transition>

    <div v-show="!loading" class="svg-icon-v">
      <img src="@/assets/loading.svg" />
    </div>

    <div class="col-md-12 px-0 mt-4 only-desktop" style="padding: 10px 0 40px !important">
      <button
        v-if="loading && getServices_.length > 0"
        alt="Regresar a una vista anterior" aria-label="Regresar a una vista anterio"
        @click="back"
        type="button"
        class="btn btn-default-blue-fill btn-back-footer-cart btn-back-footer"
        style="max-width: 180px"
      >
        Atrás
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HeaderNavComponent from "../HeaderNavComponent";
import CardService from "../CardService";
import { selectp } from "@/helpers.js";

export default {
  components: {
    HeaderNavComponent,
    CardService,
  },
  props: ["category", "subCategories", "step"],
  data: () => ({
    selectedCategory: null,
    loading: false,
    mount: false,
    indexCategory: 0,
    services: [],
    state_sort: true,
  }),
  watch: {
    step(step, oldStep) {
      if (oldStep == 4) {
        this.loading = true;
        if (this.subCategories.length == 0) {
          this.back();
        }
      } else {
        this.loading = false;
      }
    },
    subCategories() {
      this.loading = false;

      if (this.subCategories.length > 0) {
        if (!this.mount) {
          setTimeout(() => {
            this.mount = true;
          }, 50);
        }
        if (this.indexCategory == -1) {
          this.indexCategory = 0;
        }
        this.selectedCategory = this.subCategories[this.indexCategory].categoria;
      }
    },

    selectedCategory(val) {
      this.loading = false;
      this.indexCategory = this.subCategories.findIndex((item) => item.categoria.id == val.id);
      if (val) {
        this.getServices(val.id).then(() => {
          this.loading = true;
          this.services = this.categoriesSort(this.getServices_);
        });
      }
    },
    // se encarga de formatear array en caso de que los servicios sean de microsoft
    // getServices_(){
    // console.log("getServices_: ", this.getServices_);
    // if(this.getServices_.length > 0 && this.getServices_[0].categoria.nombre == 'Microsoft Office 365'){
    //   console.log("se ejecuta formatObject por el watch: ", this.getServices_);
    //   this.formatObject(this.getServices_);
    // }
      
    // }
  },
  computed: {
    ...mapGetters(["getServices_", "getCategories_"]),
    selecSubCategories() {
      if (this.subCategories.length != 0) {
        return this.subCategories;
      } else {
        return "Cargando...";
      }
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["getServices"]),
    selectp,
    back() {
      this.loading = false;
      this.selectedCategory = null;
      this.$emit("back");
    },
    next_step(item) {
      this.$emit("next", { category: this.selectedCategory, service: item });
    },
    // Ordena los servicios/categorias por el precio
    categoriesSort(services) {
      try {
        if(this.state_sort) return services.sort((a, b) => a.precio - b.precio);
        else{
          return services.sort((a, b) => b.precio - a.precio);
        }
      } catch (error) {
        console.log("errror sort: ", error);
        return services
      }
    },
    sortServiceLoaded(){
      this.state_sort = !this.state_sort;
      this.services = this.categoriesSort( this.services, this.state_sort);
    }
  },
};
</script>

<style scoped>
.title-bar-left {
  text-align: left;
  font-size: 20px;
}

.title-bar-right {
  text-align: right;
  font-size: 20px;
}
</style>

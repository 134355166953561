<template>
  <div class="wrapper targetDiv wrapper-v" :class="{ 'collapse-wrapper': !collapse }">
    <div class="cd-filter-block2">
      <div class="container-collapse" style="margin-bottom: 0px">
        <div class="collapse show fade-in" id="config_01" style="padding-left: 0">
          <div class="collapse-target" style="display: flex; flex-flow: row wrap">
            <div class="col-12 px-0">
              <!-- COMPONENTE SERVICIO -->
              <p style="font-size: 17px; text-align: left; margin-bottom: 15px">
                A continuación se muestran los campos que deben ser completados para poder realizar la
                habilitación de su servicio. Por favor, ingrese los datos requeridos.
              </p>

              <div v-for="(campo, idx) in orderCampos" :key="idx">
                <div v-if="campo.tipo == 'seleccion'" class="cd-filter-block" style="margin-bottom: 0px">
                  <ul
                    v-if="campo.placeholder != 'sistema_operativo_linux'"
                    class="radio-group radios-filter cd-filter-content list ul-v-list"
                  >
                    <label class="vue-input"
                      ><b>{{ campo.nombre }}</b>
                      <i
                        v-tooltip="contentTooltip(campo.texto_ayuda)"
                        class="fas fa-question-circle tooltip-icon-v"
                        style="font-size: 16px !important"
                      ></i
                    ></label>
                    <li
                      v-for="(option, index) in returnSelectOptions"
                      :key="index"
                      style="margin: 0px !important"
                    >
                      <input
                        v-model="form[campo.id]['value']"
                        v-validate="index == 0 && campo.obligatorio == 1 ? 'required' : ''"
                        :value="option"
                        type="radio"
                        :name="campo.nombre + service.id"
                        :data-vv-as="campo.nombre"
                        :id="service.id + index"
                        :key="service.id + index"
                        @change="flag = index"
                      />
                      <label class="radio-label" :for="service.id + index">{{ option }}</label>
                    </li>
                  </ul>
                  <div v-else class="row card-checkbox px-1 resp-h" style="position: relative; top: -7px">
                    <label class="vue-input pl-2" style="text-align: left; position: relative; top: 7px"
                      ><b>{{ campo.nombre }}</b>
                      <i
                        v-tooltip="contentTooltip(campo.texto_ayuda)"
                        class="fas fa-question-circle tooltip-icon-v"
                        style="font-size: 16px !important"
                      ></i
                    ></label>
                    <ul
                      class="small-block-grid-3 pl-0"
                      style="width: 100%; display: contents; position: realtive; top: 3px"
                    >
                      <li v-for="(so, index2) in returnSelectOptionsSO" :key="index2" class="col-md-4 px-0">
                        <label>
                          <input
                            type="radio"
                            @change="selectTypeSO(so)"
                            v-validate="index2 == 0 && campo.obligatorio == 1 ? 'required' : ''"
                            :data-vv-as="campo.nombre"
                            :name="campo.nombre + service.id"
                            :id="service.id + index2"
                            :key="service.id + index2"
                            class="card-input-element"
                            :class="'radio_' + so.type"
                            :ref="'radio_' + so.type"
                          />

                          <div
                            class="card-input"
                            :class="{
                              'diabled-radio-so':
                                form[campo.id]['value'] != null && so.type != selectedTypeSO,
                            }"
                          >
                            <div class="card-input-body">
                              <div
                                class="icon"
                                :class="[
                                  so.type == 'Debian' ? 'debian ' : '',
                                  so.type == 'Centos' ? 'centos ' : '',
                                  so.type == 'Ubuntu' ? 'ubuntu' : '',
                                  so.type == 'Fedora' ? 'fedora ' : '',
                                  so.type == 'Almalinux' ? 'almalinux ' : '',
                                ]"
                              ></div>
                              {{ so.type }}
                            </div>

                            <div class="btn-group bootstrap-select">
                              <select
                                @change="selectSO($event.target.value, so.type, service.id + index2)"
                                :id="'select_' + so.type"
                                class="selectpicker show-menu-arrow"
                                data-style="select-btn"
                              >
                                <option v-for="(option, index3) in so.options" :key="index3">
                                  {{ option }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </label>
                      </li>
                    </ul>
                  </div>

                  <FormError :error="errors.first(campo.nombre + service.id)"> </FormError>
                  <hr style="margin: 25px 0 15px 0" />
                </div>

                <!-- SEGUNDA SECCIÓN CONFIGURACIÓN -->
                <div v-if="campo.tipo == 'dominio' || campo.tipo == 'textocorto'" style="width: 100%">
                  <div v-if="campo.tipo == 'dominio' || campo.tipo == 'textocorto'" style="display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin-bottom: 15px; gap:0px;">
                    <label
                      v-if="flag != null"
                      class="vue-input col-12 col-md-12 pl-0"
                      style="text-align: left"
                      ><b>{{ campo.nombre }}</b
                      >
                      <i 
                        v-tooltip="contentTooltip(campo.nombre == 'Alias de dominio' && campo.tipo == 'dominio' ? 'Sin www ni https, ej: dominio.cl' : campo.texto_ayuda)"
                        class="fas fa-question-circle tooltip-icon-v"
                        style="font-size: 16px !important"
                      ></i>
                    </label>

                    <!-- DOMINIO -->
                    <div v-if="campo.tipo == 'dominio' && campo.nombre != 'Dominio SSL'" style="display: flex; flex-flow: row wrap; width: 100%; gap:10px;">
                      <div class="col-md-12 px-0" style="display: flex; flex-flow: row wrap;gap: 5px;" >
                        <div class="domain_info col-md-2">
                          <span>www.</span>
                        </div>
                        <div v-if="flag == 0 && campo.tipo == 'dominio'" class="col-md-9" style="padding: 0px;position: relative; z-index: 1;">
                          <input
                            v-model="form[campo.id]['value']"
                            @keyup.enter="addServiceConfiguration_()"
                            autocomplete="off"
                            v-validate="campo.obligatorio == 1 ? 'required' : ''"
                            type="text"
                            class="input"
                            :name="campo.nombre"
                            :class="[
                              verify ? 'disabled-input' : '',
                              errors.first(campo.nombre) ? 'vue-error' : '',
                            ]"
                            style="border-top-left-radius: 0px; border-bottom-left-radius: 0px"
                            placeholder="Ingrese nombre de dominio"
                            :disabled="verify"
                            v-lowrcase
                            maxlength="50"
                          />
                        </div>

                        <div v-if="flag != 0 && campo.tipo == 'dominio'" class="col-md-6 px-0" style="gap: 5px;">
                          <div class="cd-filter-block checkbox-group" style="display: flex; gap:5px;position: relative; z-index: 1;">
                            <!-- <div class="domain_info">
                              <span>www</span>
                            </div> -->
                            <input
                              v-model="form[campo.id]['value']"
                              @keyup.enter="validateDomain()"
                              autocomplete="off"
                              type="text"
                              class="input"
                              style="border-radius: 0px; z-index: 1;"
                              v-validate="campo.obligatorio == 1 ? 'required' : ''"
                              :name="campo.nombre"
                              :class="[
                                verify ? 'disabled-input' : '',
                                errors.first(campo.nombre) ? 'vue-error' : '',
                              ]"
                              placeholder="Ingrese nombre de dominio"
                              :disabled="verify"
                              v-lowrcase
                              maxlength="50"
                            />
                          </div>
                        </div>

                        <div v-if="flag != 0 && campo.tipo == 'dominio'" class="col-md-3 div-domain px-0">
                          <div class="cd-filter-block">
                            <div class="cd-filter-content cd-select">
                              <select
                                v-model="selectedType"
                                class="select-custom-form aparence-none-v dom-2-esp"
                                style="
                                  border-top-left-radius: 0px;
                                  border-bottom-left-radius: 0px;
                                  padding: 0 20px;
                                "
                                :class="[
                                  verify ? 'disabled-input' : '',
                                  errors.first(campo.nombre) ? 'vue-error-right' : '',
                                ]"
                                v-lowrcase
                                :disabled="verify"
                              >
                                <option v-for="(option, idy) in optionsType" :key="idy" :value="option.value">
                                  {{ option.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="flag != 0 && campo.tipo == 'dominio'"
                        class="btn-pos-esp col-md-3 pxn-0 btn-top-m" style="padding: 0px;"
                      >
                        <a
                          @click="validateDomain()"
                          class="btn btn-default-grad-purple-fill white-font"
                          :class="[verify ? 'verify-class' : 'normal-class']"
                          style="width: 100%"
                          ><span v-show="!verify">Verificar</span>
                          <div v-show="verify" class="spinner-border text-info" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </a>
                      </div>

                      <div
                        v-else
                        class="btn-pos-esp btn-top-m "
                        :class="[orderCampos.length > 2 ? 'col-md-12 px-0 mt-1' : 'col-md-3 pxn-0']"
                        style="padding: 0px;"
                      >
                        <a
                          @click="addServiceConfiguration_"
                          class="btn btn-default-grad-purple-fill white-font btn-add-config"
                          :class="[verify ? 'verify-class' : 'normal-class']"
                        >
                          <div v-show="!verify">
                            <span v-if="typeof service.pos === 'number' || save">Guardar</span
                            ><span v-else>Agregar</span>
                          </div>

                          <div v-show="verify" class="spinner-border text-info" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </a>
                      </div>

                    </div>

                    <div style="z-index: 0;" class="fix-error-pos">
                      <FormError
                        v-if="campo.tipo == 'dominio'"
                        :error="errors.first(campo.nombre)"
                        class="error-pos-dominio"
                        style="left: 0px"
                      >
                      </FormError>
                    </div>

                    <!-- TEXTOCORTO ?? -->
                    <div
                      v-if="campo.tipo == 'textocorto' || campo.nombre == 'Dominio SSL'"
                      :class="[orderCampos.length > 2 ? 'col-md-12 px-0 mb-3' : 'col-md-9 px-0']"
                    >
                      <div class="cd-filter-block checkbox-group vue-group">
                        <input
                          v-model="form[campo.id]['value']"
                          v-validate="validateTextoCorto(campo)"
                          @keyup.enter="addServiceConfiguration_()"
                          autocomplete="off"
                          type="text"
                          class="input"
                          :name="campo.nombre"
                          :placeholder="campo.nombre"
                          :class="{ 'vue-error': errors.first(campo.nombre) }"
                          maxlength="50"
                        />

                        <FormError :error="errors.first(campo.nombre)"> </FormError>
                      </div>
                    </div>

                    <div
                      v-if="
                        (campo.tipo == 'textocorto' || campo.nombre == 'Dominio SSL') &&
                        idx == orderCampos.length - 1
                      "
                      class="btn-pos-esp btn-top-m"
                      :class="[orderCampos.length > 2 ? 'col-md-12 px-0 mt-4' : 'col-md-3 pxn-0']"
                      style="padding: 0px;"
                    >
                      <a
                        @click="addServiceConfiguration_"
                        class="btn btn-default-grad-purple-fill white-font btn-add-config"
                      >
                        <div v-show="!verify">
                          <span v-if="typeof service.pos === 'number' || save">Guardar</span
                          ><span v-else>Agregar</span>
                        </div>

                        <div v-show="verify" class="spinner-border text-info" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="slide-fade2">
      <div
        v-show="result"
        style="text-align: left; display: flex; justify-content: space-between"
        class="mb-2"
      >
        <div v-if="result">
          <span v-if="result.status == 'free'" style="display: flex"
            ><i class="fas fa-check-circle icon-config-success" style=""></i>
            <div class="span-config-success-text">{{ result.domain }} disponible.</div>
          </span>

          <span v-else style="display: flex; margin-top: 10px"
            ><i class="fas fa-times-circle icon-config-success" style="color: red"></i>
            <div class="span-config-success-text">{{ result.domain }} no disponible.</div>
          </span>
        </div>
      </div>
    </transition>

    <transition name="slide-fade2">
      <div
        v-show="serviceValidate && service_finish && errors.items.length == 0 && step_config == 2"
        style="text-align: left; display: flex; justify-content: space-between"
      >
        <span style="display: flex"
          ><i class="fas fa-check-circle icon-config-success" style=""></i>
          <div class="span-config-success-text">
            {{ service.nombre }} configurado correctamente.<br />
            <span class="span-adicionales-v"
              >Revisa nuestros complementos opcionales que tenemos para ofrecerte.</span
            >
          </div>
        </span>
        <i
          v-if="!collapse"
          class="fa fa-chevron-down btn-confirm-config"
          @click="collapse = !collapse"
          data-toggle="collapse"
          href="#config_01"
          role="button"
          aria-expanded="true"
          aria-controls="config_01"
          aria-hidden="true"
          style="position: relative; top: 6px; pointer-events: none; color: white"
        ></i>

        <i
          v-else
          class="fa fa-chevron-up btn-confirm-config"
          @click="collapse = !collapse"
          data-toggle="collapse"
          href="#config_01"
          role="button"
          aria-expanded="true"
          aria-controls="config_01"
          aria-hidden="true"
          style="position: relative; top: 6px; pointer-events: none; color: white"
        ></i>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { toArray, contentTooltip, onlyLettersAndDot } from "@/helpers";
import { blacklist } from "@/blacklist.js";
import FormError from "./FormError";
const isValidHostname = require("is-valid-hostname");

export default {
  components: {
    FormError,
  },
  props: ["campos", "id", "type", "service", "validate_", "step_config"],
  data: () => ({
    form: {},
    selectedType: "cl",
    optionsType: [],
    serviceValidate: false,
    campo_dominio: null,
    verify: false,
    result: null,
    flag: 0,
    delay_close: 600,
    save: false,
    selectedTypeSO: null,
    selectedSO: null,
    select_form: {},
    check: false,
    service_finish: false,
    collapse: true,
  }),
  watch: {
    service() {
      this.initComponent();
    },
    flag() {
      var campo = this.toArray(this.form).find((c) => c.nombre == this.data_.campo_tipo_dominio.name);
      if (campo) {
        this.$validator.reset();
      }
    },
    validate_() {
      this.addServiceConfiguration();
    },
    step_config(step) {
      if (step == 2) {
        $(".btn-confirm-config")[0].click();
      } else {
        this.collapse = true;
      }
    },
  },

  created() {
    this.initComponent();
    if(this.form && Object.values(this.form)[1]  && Object.values(this.form)[1].value == "Tengo mi dominio\r"){
      this.flag = 0;
    }else{
      this.flag = 1;
    }
  },
  mounted() {
    this.returnSelectOptionsSO.forEach((value) => {
      $("#select_" + value.type).selectpicker();
    });
  },
  computed: {
    ...mapGetters(["getDetail", "getCart", "getData"]),
    data_() {
      return this.getData.carrito;
    },
    orderCampos() {
      return this.campos
        .filter((campo) => campo.pivot.completable == 1)
        .sort((a, b) => a.indexar - b.indexar);
    },
    returnSelectOptions() {
      let options = [];
      this.campos.forEach((value) => {
        if (value.tipo == "seleccion") {
          options = value.opciones.split("\n");
        }
      });
      return options;
    },
    returnSelectOptionsSO() {
      let options = [];
      let option = null;
      this.returnSelectOptions.forEach((value, index1) => {
        option = value.split(" ");
        var target = options.find((so) => so.type == option[0]);

        if (target) {
          var index = options.findIndex((so) => so.type == option[0]);
          options[index]["options"].push(value);
        } else {
          options.push({ type: option[0], options: [value] });
        }
      });
      return options;
    },
  },
  methods: {
    ...mapMutations([
      "addServiceToDetail",
      "updateServiceDetail",
      "addServiceToDetailAdicional",
      "addIndexServiceToDetail",
    ]),
    ...mapActions(["checkDomain", "getServices_", "checkDomainExist"]),
    toArray,
    contentTooltip,
    onlyLettersAndDot,
    validateTextoCorto(campo) {
      let validate = "";
      if (campo.obligatorio == 1) {
        validate = "required";
      }

      if (campo.id == this.data_.campo_remitente?.id) {
        validate = "required|email";
      }
      return validate;
    },
    selectSO(name, type, radio) {
      $("#" + radio + "").prop("checked", true);
      this.returnSelectOptionsSO.forEach((value) => {
        if (value.type != type) {
          $("#select_" + value.type).selectpicker("setStyle", "disabled-radio-so", "add");
        } else {
          $("#select_" + value.type).selectpicker("setStyle", "disabled-radio-so", "remove");
        }
      });
      this.select_form[type] = name;
      this.form[this.data_.campo_sistema_operativo_linux.id]["value"] = name;
    },
    selectTypeSO(so) {
      this.returnSelectOptionsSO.forEach((value) => {
        if (value.type != so.type) {
          $("#select_" + value.type).selectpicker("setStyle", "disabled-radio-so", "add");
        } else {
          $("#select_" + value.type).selectpicker("setStyle", "disabled-radio-so", "remove");
        }
      });

      this.selectedTypeSO = so.type;
      if (this.select_form[so.type]) {
        this.form[this.data_.campo_sistema_operativo_linux.id]["value"] = this.select_form[so.type];
      } else {
        this.select_form[so.type] = so.options[0];
        this.form[this.data_.campo_sistema_operativo_linux.id]["value"] = so.options[0];
      }
    },
    validateDomain() {
      this.$Progress.start();

      let formArr = Object.entries(this.form);
      if(formArr[0][1].value && this.validCampoDominio(formArr[0][1].value)){
        this.$validator.validate().then((esValido) => {
        if (esValido) {
          this.verify = true;

          let campo = this.toArray(this.form).find((item) => item.tipo == "dominio");
          let params = {
            tld: this.selectedType,
            dominio: campo.value,
          };

          this.checkDomain(params).then((res) => {
            if (!res.errors) {
              this.result = res.data?.result[0];
              this.verify = false;

              if (this.result.status == "free") {
                this.addServiceConfiguration(this.selectedType);
              } else {
                this.$toast.error("Error, dominio ingresado no disponible.");
                this.$Progress.fail();
              }
            } else {
              this.verify = false;
              this.$toast.error("Error al intentar configurar el servicio.");
              this.$Progress.fail();
              this.$validator.errors.add({
                field: campo.nombre,
                msg: "El dominio no es válido",
              });
              return false;
            }
          });
        } else {
          this.$toast.error("Error al intentar configurar el servicio.");
          this.$Progress.fail();
        }
      });
      }else{
        this.$Progress.fail();
        this.$toast.error("El campo no puede estar vacio.");
        return
      }
    },

    validCampoDominio(campo){
      let campoValido = false;
      function isEmptyOrSpaces(str) {
         return str.trim().length === 0; 
        } 
      if (!isEmptyOrSpaces(campo)) { 
        console.log("El string no está vacío o contiene solo espacios."); 
        campoValido = true
      }
      return campoValido
    },
    addServiceConfiguration_() {
      this.$Progress.start();
      let formArr = Object.entries(this.form);
      if(formArr[0][1].value && this.validCampoDominio(formArr[0][1].value)){
        this.verify = true;
        this.addServiceConfiguration();
      }else{
        this.$Progress.fail();
        this.$toast.error("El campo no puede estar vacio.");
      }
    },
    addServiceConfiguration(tld) {
      this.$validator.validate().then((esValido) => {
        if (esValido) {
          let ser = this.service;
          if (tld) {
            tld = tld.toLowerCase();
          }

          let params = {
            configuracion: JSON.parse(JSON.stringify(this.toArray(this.form))),
            estado: 1,
          };

          // VALIDACIONES
          /* --------- VALIDACION NOMBRA CAMPO HOSTANAME O CAMPO TIPO DOMINIO --------- */

          this.validateFields(tld, ser, params).then((res) => {
            if (res) {
              ser["configuracion"] = params.configuracion;
              ser["estado"] = 1;
              if(this.service["configuracion"][0]["value"]){
                this.service["configuracion"][0]["value"] = this.service["configuracion"][0]["value"].toLocaleLowerCase();
              }

              if (this.type == 1) {
                this.updateServiceDetail(JSON.parse(JSON.stringify(params)));
                this.serviceValidate = true;
                this.$root.$emit("configurationComplet");

                this.$toast.success("Servicio configurado con exito.");
                this.$Progress.finish();
                this.service_finish = true;
                this.$emit("configFinish");
              } else {
                this.addServiceToDetailAdicional(JSON.parse(JSON.stringify(ser)));
                this.serviceValidate = true;
                this.$toast.success("Servicio adicional configurado con exito.");
                setTimeout(() => {
                  this.$Progress.finish();
                  this.$emit("addAdicional");
                }, this.delay_close);
              }

              if (tld) {
                let service_ = this.optionsType.find((item) => item.value == tld).service;
                service_["configuracion"] = [
                  {
                    id: this.data_.campo_dominio.id,
                    nombre: "Dominio",
                    placeholder: "dominio",
                    tipo: "dominio",
                    value: this.service["configuracion"][0]["value"],
                    domain: true,
                  },
                ];

                let config = this.service.configuracion.find((item) => item.tipo == "dominio");
                console.log(config);
                // if (config) {
                //   service_["configuracion"].push(config);

                //   service_["configuracion"][0]["domain"] = true;
                // }

                this.addServiceToDetailAdicional(JSON.parse(JSON.stringify(service_)));
              } else {
                this.result = null;
              }
            } else {
              this.result.status = "false";
              this.service_finish = false;
            }
          });
        } else {
          this.$toast.error("Error al intentar configurar el servicio.");
          this.$Progress.fail();
        }
        this.verify = false;
      });
    },

    async validateFields(tld, ser, params) {
      // SELEECION DE CAMPO
      let campo = null;
      this.data_.campos_dominio.forEach((value) => {
        var c = this.toArray(this.form).find((item) => item.nombre == value.name);
        if (c) {
          campo = c;
        }
      });

      if (!campo) {
        this.data_.campos_hostname.forEach((value) => {
          var c = this.toArray(this.form).find((item) => item.nombre == value.name);
          if (c) {
            campo = c;
          }
        });
      }

      if (!campo) {
        campo = this.toArray(this.form).find((item) => item.tipo == "dominio");
      }

      // VALIDACIÓN CAMPO
      if (campo || typeof tld === "string") {
        let nombre = null;
        let domain_ = null;
        let input = null;
        if (typeof tld === "string") {
          var domain = params.configuracion[0]["value"];
          params.configuracion[0]["value"] = domain + "." + tld;
          nombre = ser["nombre"] + " (" + params.configuracion[0]["value"].toLocaleLowerCase() + ")";
          domain_ = domain + "." + tld;
          input = "Tipo de Dominio";
        } else {
          nombre = ser["nombre"] + " (" + campo.value.toLocaleLowerCase() + ")";
          domain_ = campo.value.toLocaleLowerCase();
          input = campo.nombre;
        }
        ser["nombre_"] = nombre;
        ser["domain_"] = domain_;
        params.nombre_ = nombre;
        params.domain_ = domain_;

        // VALIDACION DOMINIO
        if (
          campo.tipo == "dominio" ||
          (this.data_.campos_dominio.find((item) => item.name == campo.nombre) && !tld)
        ) {
          this.tipo_dominio = true;
          let error = false;
          let dom = null;
          dom = domain_.split(".");
          if (dom.length < 2 || dom.length > 2 || dom[1]?.length > 6) {
            error = true;
          } else if (!this.CheckIsValidDomain(domain_)) {
            error = true;
          }

          if (error) {
            this.verify = false;
            this.$toast.error("Error al intentar configurar el servicio.");
            this.$Progress.fail();
            this.$validator.errors.add({
              field: campo.nombre,
              msg: "El dominio no es válido",
            });
            return false;
          }
        } else if (this.data_.campos_hostname.find((item) => item.name == campo.nombre) && !tld) {
          if (!isValidHostname(domain_)) {
            this.verify = false;
            this.$toast.error("Error al intentar configurar el servicio.");
            this.$Progress.fail();
            this.$validator.errors.add({
              field: campo.nombre,
              msg: "El hostname ingresado, no es válido",
            });
            return false;
          }
        }

        // VALIDACION CAMPO REPETIDO
        // ADICIONALES DETALLE
        let service1 = null;
        let x = null;
        let servi1 = null;

        // CONFIGURACIÓN SERVICIO PRINCIPAL O ADICIONAL

        if (this.getDetail.servicios_adicionales.length > 0) {
          servi1 = this.getDetail.servicios_adicionales.find((item) => item.domain_ == domain_);
        }

        if (servi1) {
          if (service1.id == ser.id) {
            x = true;
          }
        }

        // PRINCIPAL DETALLE
        let service2 = null;
        if (this.getDetail.configuracion.length > 0) {
          service2 = this.getDetail.configuracion.find((item) => item.value == domain_);
        }

        // ADICIONALES CARRITO
        let service3 = null;
        if (this.getCart[0]?.servicios_adicionales.length > 0) {
          service3 = this.getCart[0].servicios_adicionales.find((item) => item.domain_ == domain_);
        }

        // PRINCIPAL CARRITO
        let service4 = null;
        if (this.getCart[0]?.configuracion.length > 0) {
          service4 = this.getCart[0].configuracion.find((item) => item.value == domain_);
        }

        let ser_ = null;
        let msg = null;
        if (service1) {
          ser_ = service1;
          msg =
            'Campo registrado en el campo "' +
            service1?.nombre +
            '" (servicios adicionales de esta configuración)';
        } else if (service2) {
          ser_ = service2;
          msg =
            'Campo registrado en el campo "' +
            service2?.nombre +
            '" (servicio principal de esta configuración)';
        } else if (service3) {
          ser_ = service3;
          msg =
            'Campo registrado en el campo "' +
            service3?.nombre +
            '" (servicios adicionales del carrito de compras)';
        } else {
          ser_ = service4;
          msg =
            'Campo registrado en el campo "' +
            service4?.nombre +
            '" (servicio principal del carrito de compras)';
        }

        let val = false;
        if (this.type == 1) {
          if (service2) {
            val = true;
          }
        } else {
          if (x) {
            val = true;
          }
        }

        if (ser_ && !val) {
          this.verify = false;
          this.$toast.error("Error al intentar configurar el servicio.");
          this.$Progress.fail();
          this.$validator.errors.add({
            field: input,
            msg: msg,
          });

          return false;
        }

        // BLACKLIST
        let check_blacklist = false;
        blacklist.forEach((value) => {
          if (value == domain_) {
            check_blacklist = true;
          }
        });

        if (check_blacklist == true) {
          this.verify = false;
          this.$toast.error("Error al intentar configurar el servicio.");
          this.$Progress.fail();
          this.$validator.errors.add({
            field: input,
            msg: "El dominio " + domain_ + ", se encuentra bloqueado",
          });
        }

        // VALIDACIÓN DOMINIO EXISTENTE
        this.check = false;
        const check = this.checkDomainExist(domain_).then((res) => {
          if (res.existe == 1) {
            this.verify = false;
            this.$toast.error("Error al intentar configurar el servicio.");
            this.$Progress.fail();
            this.$validator.errors.add({
              field: input,
              msg: "Este dominio ya está registrado. Si tu cuenta de hosting está suspendida o deseas cambiar de plan, te invitamos a contactarnos a través de cualquiera de nuestros canales de atención disponibles.",
            });
            this.check = true;
            return false;
          }
        });

        return check.then((value) => {
          if (check_blacklist || this.check) {
            return false;
          } else {
            return true;
          }
        });
        // FIN VALIDACIÓN
      } else {
        return true;
      }
    },

    CheckIsValidDomain(domain) {
      var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
      return domain.match(re);
    },

    initComponent() {
      this.$validator.reset();

      if (this.$route.query.index && this.service.url) {
        this.service["pos"] = parseInt(this.$route.query.index);
        this.service["configuracion"] = this.getCart[this.$route.query.index]["configuracion"];
      }

      this.orderCampos.forEach((item, index) => {
        this.form[item.id] = {
          id: item.id,
          nombre: item.nombre,
          placeholder: item.placeholder,
          tipo: item.tipo,
          value: null,
        };
      });

      var campo = this.toArray(this.form).find((c) => c.nombre == this.data_.campo_tipo_dominio.name);

      if (campo) {
        campo.value = this.returnSelectOptions[0];
        this.campo_dominio = campo.id;
        this.getServices_().then((res) => {
          var names = [];
          res.forEach((value, index) => {
            var name = value.nombre;
            var name_split = name.split(".");
            var params = {
              name: "." + name_split[1].toLowerCase(),
              value: name_split[1].toLowerCase(),
              service: value,
            };
            names.push(params);
          });
          this.selectedType = names[0].value;
          this.optionsType = names;
        });
      }

      if (typeof this.service?.pos === "number") {
        this.service.configuracion.forEach((value) => {
          var campo = this.toArray(this.form).find((c) => c.id == value.id);

          campo.value = value.value;

          var name = value.value.split(" ");
          if (value.tipo == "seleccion") {
            setTimeout(() => {
              $(".radio_" + name[0]).prop("checked", true);
            }, 100);
          }
        });
      }

      // CAMPO TIPO DOMINIO
      if (this.orderCampos.length > 1 && this.service.cuantificable !== "1") {
        if (this.type == 1) {
          if ((this.service.id = this.getDetail.id)) {
            this.save = true;
            let id = null;
            let servi = null;
            let propio = false;
            this.getDetail.configuracion.forEach((value) => {
              if (value.tipo == "dominio") {
                var s = JSON.parse(JSON.stringify(value));
                var c = s.value.split(".");
                s.value = c[0];
                servi = s;
                id = value.id;
              }

              if (
                value.id == this.data_.campo_tipo_dominio.id &&
                value.value == this.data_.campo_tipo_dominio.comprar_dominio
              ) {
                propio = true;
              }

              this.$set(this.form, value.id, value);

              if (value.id == this.data_.campo_tipo_dominio.id) {
                if (this.data_.campo_tipo_dominio.comprar_dominio == value.value) {
                  this.flag = 0;
                } else {
                  this.flag = 1;
                }
              }
            });

            if (propio) {
              this.$set(this.form, id, servi);
            }
          }
        } else {
          var service = this.getDetail.servicios_adicionales.find((c) => c.id == this.service.id);
          if (service) {
            this.save = true;
            let id = null;
            let servi = null;
            let propio = false;

            service.configuracion.forEach((value) => {
              console.log(this.service);
              if (value.tipo == "dominio") {
                var s = JSON.parse(JSON.stringify(value));
                var c = s.value.split(".");
                s.value = c[0];
                servi = s;
                id = value.id;
              }

              if (
                value.id == this.data_.campo_tipo_dominio.id &&
                value.value == this.data_.campo_tipo_dominio.comprar_dominio
              ) {
                propio = true;
              }

              this.$set(this.form, value.id, value);

              if (value.id == this.data_.campo_tipo_dominio.id) {
                if (this.data_.campo_tipo_dominio.comprar_dominio == value.value) {
                  this.flag = 0;
                } else {
                  this.flag = 1;
                }
              }
            });

            if (propio) {
              this.$set(this.form, id, servi);
            }
          }
        }
      }
    },
    toLower(text){
      if(text.length > 0){
        return text.toLocaleLowerCase();
      }
    }
  },

  directives: {
    lowrcase: {
      update(el) {
        if(el.value.length > 0){
          el.value = el.value.toLocaleLowerCase();
        }
      },
    },
  },
};
</script>

<style scoped>
.disabled-input {
  opacity: 0.7;
  background: #ececec !important;
  background-color: #ececec !important;
}
.error-pos-dominio {
  position: relative;
  top: 5px;
  left: 17px;
  font-size: 15px !important;
  line-height: 18px !important;
  height: auto;
}

.normal-class {
  padding: 15px !important;
}

.verify-class {
  background: #d6d6d6 !important;
  opacity: 0.7;
  pointer-events: none;
  cursor: not-allowed !important;
  padding: 6px !important;
}
.vue-group {
  margin-bottom: 10px;
}
.vue-error {
  border-color: #dc3545 !important;
}

.vue-error-left {
  border-right: 0;
  border-color: #dc3545 !important;
}

.vue-error-right {
  border-left: 0;
  border-color: #dc3545 !important;
}
.vue-input {
  float: left;
  margin-bottom: 4px;
}

.wrapper-v {
  padding: 45px;
  margin-top: 0px;
}

.collapse-wrapper {
  padding: 20px 45px !important;
}


.domain_info{
  background-color: var(--grey-color);
    border: none !important;
    background-position: right 8px bottom 50%;
    height: 54px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
@media (max-width: 576px) {
  .wrap-blog .wrapper {
    padding: 20px !important;
  }
}
@media (max-width: 768px) {
  .pxn-0 {
    padding-right: 0;
    padding-left: 0;
  }
}

.slide-fade2-enter-active {
  transition: all 1s ease;
}
.slide-fade2-leave-active {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade2-enter,
.slide-fade2-leave-to {
  transform: translateX(10px);
  opacity: 0;
  overflow: hidden;
}

.slide-fade2-leave-active {
  display: none !important;
}
</style>
